import { getOptionsAsObject } from '../utils/LocalStorage';
import { isValidJSON } from '../utils/utils';
import BaseService from './BaseService';

class LoginService extends BaseService {
  login = (body) => {
    return this.request({
      method: 'POST',
      body,
      url: `/api/login`,
    });
  };
  logout = (userId) => {
    return this.request({
      url: `/api/logout/${userId}`,
    });
  };
  getUserProfile = () => {
    const { collegeID, userID } = getOptionsAsObject(`userProfile`);
    const url = `/profile/profile?collegeID=${collegeID}&userID=${userID}`;
    return this.request({ url });
  };
  updateDisplayPicture = async (file) => {
    const { collegeID, userID } = getOptionsAsObject(`userProfile`);
    const url = `${process.env.REACT_APP_BASE_URL}/profile/changedp`;
    const { authorization } = getOptionsAsObject('userProfile');
    let jwttoken = authorization;
    var myHeaders = new Headers();
    myHeaders.append('Authorization', jwttoken);

    var fd = new FormData();
    fd.append('file', file);
    fd.append('collegeID', collegeID);
    fd.append('userID', userID);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: fd,
      redirect: 'follow',
    };

    return fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log('error', error));
  };
  uploadScratchProject = async (file) => {
    const { collegeID, userID } = getOptionsAsObject(`userProfile`);
    const url = `${process.env.REACT_APP_BASE_URL}/profile/projectUpload`;
    const { authorization } = getOptionsAsObject('userProfile');
    let jwttoken = authorization;
    var myHeaders = new Headers();
    myHeaders.append('Authorization', jwttoken);

    var fd = new FormData();
    fd.append('file', file);
    fd.append('collegeID', collegeID);
    fd.append('userID', userID);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: fd,
      redirect: 'follow',
    };

    return fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (isValidJSON(result)) {
          return JSON.parse(result);
        }
      })
      .catch((error) => console.log('error', error));
  };

  updateProfile = async (body) => {
    const { collegeID, userID } = getOptionsAsObject(`userProfile`);
    const url = `/profile/profile`;
    return this.request({
      method: 'POST',
      url,
      body: {
        ...body,
        collegeID,
        userID,
      },
    });
  };

  fetchStudentDashboard = () => {
    const { collegeID, userID } = getOptionsAsObject(`userProfile`);
    const url = `/api/student_dashboard?collegeID=${collegeID}&userID=${userID}`;
    return this.request({
      method: 'GET',
      url,
    });
  };

  fetchStudentPerformance = () => {
    const { collegeID, userID } = getOptionsAsObject(`userProfile`);
    const url = `/api/student_chapterwise_quiz_dashboard?collegeID=${collegeID}&userID=${userID}`;
    return this.request({
      method: 'GET',
      url,
    });
  };

  // Teacher Dashboard

  getBasicTeacherInfo = () => {
    const { collegeID, userID } = getOptionsAsObject(`userProfile`);
    const url = `/api/teacher_dashboard?collegeID=${collegeID}&userID=${userID}`;
    return this.request({
      method: 'GET',
      url,
    });
  };

  getAllEnrolledStudents = ({ start = 0, limit = 10 }) => {
    const { collegeID, userID } = getOptionsAsObject(`userProfile`);
    const url = `/teacher/get-all-students-by-teacher?collegeID=${collegeID}&userID=${userID}&start=${start}&limit=${limit}`;
    return this.request({
      method: 'GET',
      url,
    });
  };
  getAllCourseOffered = () => {
    const { collegeID, userID } = getOptionsAsObject(`userProfile`);
    const url = `/api/user-dashboard?collegeID=${collegeID}&userID=${userID}&start=0&limit=6`;
    return this.request({
      method: 'GET',
      url,
    });
  };
  getWeeklyTechserSchedule = ({ from_date, to_date }) => {
    const { collegeID, userID } = getOptionsAsObject(`userProfile`);
    const url = `meeting/get-today-meeting`;
    return this.request({
      method: 'POST',
      url,
      body: {
        collegeID,
        userID,
        from_date,
        to_date,
      },
    });
  };

  getMonthlyAttendance = ({ from_date, to_date }) => {
    const { collegeID, userID: teacherID } = getOptionsAsObject(`userProfile`);
    const url = `meeting/teacher-attendance-report`;
    console.log({ collegeID, teacherID, from_date, to_date });
    return this.request({
      method: 'POST',
      url,
      body: {
        collegeID,
        teacherID,
        from_date,
        to_date,
      },
    });
  };

  // New Teacher Dashboard

  getBasicTeacherInfo2 = (classID = '') => {
    const { collegeID, userID } = getOptionsAsObject(`userProfile`);
    let url = `/api/teacher_details?collegeID=${collegeID}&userID=${userID}`;
    if (classID) {
      url += `&classID=${classID}`;
    }
    return this.request({
      method: 'GET',
      url,
    });
  };

  getTeacherGradeList = () => {
    const { collegeID, userID } = getOptionsAsObject(`userProfile`);
    const url = `/api/teacher_class?collegeID=${collegeID}&userID=${userID}`;
    return this.request({
      method: 'GET',
      url,
    });
  };

  getClassPerformance = (classID) => {
    const { collegeID, userID } = getOptionsAsObject(`userProfile`);
    const url = `/api/teacher_quiz_data?collegeID=${collegeID}&userID=${userID}&classID=${classID}`;
    return this.request({
      method: 'GET',
      url,
    });
  };

  getHomeworkData = (classID) => {
    const { collegeID, userID } = getOptionsAsObject(`userProfile`);
    const url = `/api/teacher_homework_data?collegeID=${collegeID}&userID=${userID}&classID=${classID}`;
    return this.request({
      method: 'GET',
      url,
    });
  };
}

export default LoginService;
